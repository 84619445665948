<template>
    <div class="rb-view">
        <div>
            <div>
                <el-form :ref="search_form_name" :model="search_option" size="mini" inline>
                    <el-row>
                        <el-col :span="4">
                            <el-form-item label="阵营:">
                                <el-radio-group v-model="search_option.camp" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="red">红方</el-radio-button>
                                    <el-radio-button label="blue">蓝方</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="性别:">
                                <el-radio-group v-model="search_option.gender" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="unknow">未知</el-radio-button>
                                    <el-radio-button label="male">男</el-radio-button>
                                    <el-radio-button label="female">女</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="4"><el-form-item label="ID:"><el-input clearable size="mini" v-model="search_option.id"></el-input></el-form-item></el-col>
                        <el-col :span="4"><el-form-item label="OpenID:"><el-input clearable v-model="search_option.open_id"></el-input></el-form-item></el-col>
                        <el-col :span="4"><el-form-item label="UnionID:"><el-input clearable v-model="search_option.union_id"></el-input></el-form-item></el-col>
                        <el-col :span="4"><el-form-item label="昵称:"><el-input clearable v-model="search_option.nick_name"></el-input></el-form-item></el-col>
                        <el-col :span="4"><el-form-item label="真实姓名:"><el-input clearable v-model="search_option.real_name"></el-input></el-form-item></el-col>
                        <el-col :span="4"><el-form-item label="地区:"><el-input clearable v-model="search_option.region"></el-input></el-form-item></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-button type="primary" icon="el-icon-search" size="mini" style="" @click="handleSearchTap">查询</el-button>
                            <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>

            <div style="margin-top: 16px;">
                <el-table :data="page_list" size="mini" border>
                    <el-table-column label="ID" prop="id" width="150"></el-table-column>
                    <el-table-column label="头像" prop="avatar" width="49">
                        <template #default="scope">
                            <el-avatar :src="scope.row.avatar" size="small"></el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column label="昵称" prop="nick_name"></el-table-column>
                    <el-table-column label="真实姓名" prop="real_name"></el-table-column>
                    <el-table-column label="性别" prop="gender">
                        <template #default="scope">
                            <span :style="{color:params_map['gender']?params_map['gender'][scope.row.gender].color:''}">{{params_map['gender']?params_map['gender'][scope.row.gender].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="阵营" prop="camp">
                        <template #default="scope">
                            <span :style="{color:params_map['camp']?params_map['camp'][scope.row.camp].color:''}">{{params_map['camp']?params_map['camp'][scope.row.camp].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="会员等级" prop="level">
                        <template #default="scope">
                            <span :style="{color:params_map['user_level']?params_map['user_level'][scope.row.level].color:''}">{{params_map['user_level']?params_map['user_level'][scope.row.level].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="地区">
                        <template #default="scope">
                            <span>{{scope.row.country}} {{scope.row.province}} {{scope.row.city}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="登录次数" prop="logins"></el-table-column>
                    <el-table-column label="最近登录" prop="last_login_at"  width="136" :formatter="elTimeStampFormat"></el-table-column>
                    <el-table-column label="IP地址" prop="ip_address"></el-table-column>
                    <el-table-column label="操作">
                        <template #default="scope">
                            <el-button type="text" plain size="mini" @click="onRowViewTap(scope.row)">详情</el-button>
                            <el-button type="text" size="mini" @click="onSendCouponTap(scope.row)">发送优惠券</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page_option.page"
                            :page-sizes="[5,10, 20, 30, 50,100,200]"
                            :page-size="page_option.size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="page_option.total"
                    ></el-pagination>
                </div>
            </div>
        </div>


        <el-dialog v-model="dialog_coupon_select_list_visible" :title="dialog_coupon_select_list_title" @close="onTellSendCouponDialogClose" width="70%" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <coupon-select-list :user-id="dialog_coupon_select_user_id" mode="single" scene="send" @onTellDialogClose="onTellSendCouponDialogClose"></coupon-select-list>
        </el-dialog>

    </div>

</template>

<script>
    import {getList} from "@/api/hado/user";
    import {getParams} from "@/api/hado/param";
    const param_list = ['user_level','gender','camp']
    import CouponSelectList from '@/views/b/market/coupon-select-list'
    import {formatTimestamp} from "@/utils/time";
    export default {
        name: "user-list",
        components:{CouponSelectList},
        data(){
            return{
                loading:false,
                search_form_name:'user_search',
                page_option:{page:1,size:30,total:0},
                search_option:{camp:'all',gender:'all'},
                page_list:[],
                params:{user_level:[],gender:[],camp:[]},
                params_map_pure:{},
                params_map:{user_level:{},gender:{},camp:{}},

                dialog_coupon_select_list_visible:false,
                dialog_coupon_select_list_title:'请选择要派发的优惠券',
                dialog_coupon_select_user_id:'',
            }
        },
        created(){
            this.initPage()
        },
        methods:{
            initPage(){
                this.initParams().then(()=>{
                    this.getPageList({page:1,size:this.page_option.size},Object.assign({},this.search_option))
                })
            },
            initParams(){
                return new Promise((resolve,reject)=>{
                    getParams(param_list.join(','),'en').then(resp=>{
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_pure = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl=>{
                            console.log('pl:',pl)
                            let tmp_map = {}
                            let tmp_map_pure = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm=>{
                                // tmp_map[pm.key]=pm.value
                                tmp_map_pure[pm.key]=pm.value
                                tmp_map[pm.key]={key:pm.key,value:pm.value,color:pm.color}

                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value]=pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_pure[pl] = tmp_map_pure
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_pure = tmp_params_map_pure
                        this.params_map_reverse = tmp_params_map_reverse
                        // console.log('params_map:',this.params_map)
                        // console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err)=>{
                        if(err){
                            console.error(err)
                        }
                        reject(err)
                    })
                })
            },
            getPageList(pageOpt,searchOpt){
                this.loading = true;
                getList(pageOpt.page,pageOpt.size,searchOpt).then(resp=>{
                    this.loading=false;
                    this.page_list = resp.data.records;
                    this.page_option.page = pageOpt.page;
                    this.page_option.total = resp.data.total;
                    this.page_option.size = pageOpt.size;
                }).catch(()=>{
                    this.loading = false;
                })
            },
            handleSearchTap(){
                let searchOpt = Object.assign({},this.search_option)
                this.getPageList({page:1,size:this.page_option.size},searchOpt)
            },
            handleSearchClear(formName){
                this.$refs[formName].resetFields();
                this.initPage()
            },
            handleSizeChange(pageSize){
                this.getPageList({page:1,size:pageSize},this.search_option);
            },
            handleCurrentChange(pageIndex){
                this.getPageList({page:pageIndex,size:this.page_option.size},this.search_option)
            },
            onRowViewTap(row){
                console.log('onRowViewTap:',row)
            },
            onSendCouponTap(row){
                this.dialog_coupon_select_user_id = row.id
                this.dialog_coupon_select_list_visible=true
            },
            onTellSendCouponDialogClose(){
                this.dialog_coupon_select_list_visible=false
                this.dialog_coupon_select_user_id=''
            },
            elTimeStampFormat(row,column,val){
                return formatTimestamp(val)
            },
        }
    }
</script>

<style scoped>

</style>
